<template>
  <v-container fluid class="flex-container-overflow">
  <ValidationObserver v-if="!signUpSuccessful" ref="obs" v-slot="{ handleSubmit }">
    <v-form class="row row--dense justify-center align-center">
      <v-col cols="12" class="py-4">
        <div class="title text-center">
          {{ $t('Register.title') }}
        </div>
      </v-col>
      <v-col
        v-for="(field) in registerFields"
        :key="field.name"
        sm="12"
        cols="10"
        class="pb-0"
      >
        <v-col cols="12" sm="6" class="mx-auto pa-0">
          <TextFieldWithValidation
            v-model="field.model"
            :name="field.name"
            outlined
            dense
            mode="lazy"
            :label="field.label"
            :rules="field.rules"
            :type="field.type"
          />
        </v-col>
      </v-col>
      <v-col cols="8" sm="12" class="mx-auto">
        <v-col cols="12" sm="6" lg="3" class="mx-auto">
          <v-btn
            rounded
            color="primary"
            block
            :loading="loading"
            @click="handleSubmit(registerUser)"
            >{{ $t('inputLabels.register') }}</v-btn
          >
        </v-col>
      </v-col>
    </v-form>
  </ValidationObserver>
  <v-row v-else justify="center" align="center" style="height: 100%">
    <v-col cols="12" sm="6">
      <div>
        <v-card-title class="title text-center" style="word-break: break-word;">
          {{ $t('Register.successMessage') }}
        </v-card-title>
        <v-card-text>
          <p class="text-center">
            <router-link to="/login"
                         style="color: #3aa8cc; text-decoration: none;"
                         class="text-uppercase title font-weight-bold"
            >{{ $t('Register.toLogin') }}</router-link>
          </p>
        </v-card-text>
      </div>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Register',
  data() {
    return {
      registerFields: [
        {
          name: 'email',
          model: '',
          rules: 'required|email',
          label: this.$t('inputLabels.email'),
          type: 'email'
        },
        {
          name: 'first_name',
          model: '',
          rules: 'required',
          label: this.$t('inputLabels.firstName'),
          type: 'text'
        },
        {
          name: 'last_name',
          model: '',
          rules: 'required',
          label: this.$t('inputLabels.lastName'),
          type: 'text'
        },
        {
          name: 'company_vat_id',
          model: '',
          rules: 'required',
          label: this.$t('inputLabels.companyVatId'),
          type: 'text'
        },
        {
          name: 'password',
          model: '',
          rules: 'required|min:10|password_complexity',
          label: this.$t('inputLabels.password'),
          type: 'password'
        },
        {
          name: 'repeat_password',
          model: '',
          rules: 'required|min:8|password:@password',
          label: this.$t('inputLabels.repeatPassword'),
          type: 'password'
        }
      ],
      loading: false,
      signUpSuccessful: false
    };
  },
  methods: {
    async registerUser() {
      try {
        this.loading = true;
        const payload = {};
        this.registerFields.forEach((field) => {
          if (field.name === 'email') {
            payload.username = field.model;
          }
          payload[field.name] = field.model
        });
        await this.$store.dispatch('user/signUp', payload);
        this.signUpSuccessful = true;
      } catch (err) {
        const errors = err && err.response ? err.response.data : [];
        Object.keys(errors).forEach((key) => {
          if (key === 'username') {
            this.$refs.obs.setErrors({
              email: this.$t('errorMessages.userExists')
            });
          } else {
            this.$refs.obs.setErrors({
              [key]: errors[key][0]
            });
          }
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped></style>
